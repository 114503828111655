<template>
  <div class="comfrim-modal">
    <v-dialog v-model="modal" max-width="400px" content-class="confirm-dialog">
      <v-card class="py-3">
        <v-card-title class="justify-center">
          <div class="text-md-h6 mb-2">こちらを本当に削除しますか？</div>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            depressed
            outlined
            medium
            @click="deleteElement(false)"
            color="#00A497"
            class="btn-default mr-2 text-button"
          >
            キャンセル
          </v-btn>
          <v-btn
            depressed
            outlined
            medium
            @click="deleteElement(true)"
            color="white"
            class="btn-seconday px-10 text-button"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "DeleteModal",
  props: {
    modal: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  components: {},
  computed: {},
  data() {
    return {};
  },
  methods: {
      deleteElement(deleteElement) {
          this.$root.$emit("deleteElement", deleteElement);
      }
  },
};
</script>