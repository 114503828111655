<template>
  <v-container>
    <v-row>
      <v-col :cols="panel ? 9 : 12">
        <v-container id="content-container">
          <v-row>
            <v-col
              cols="12"
              v-for="(block, index) in sections"
              :key="`block-${index}`"
            >
              <Window
                is-section
                :id="`section-${index}`"
                @add:section="addSection"
                component-name="Section"
              >
                <WrapperElement
                  @click:add="panel = true"
                  @select:element="selectElement(`section-${index}`)"
                  :id="`section-${index}`"
                  :children="block.children"
                />
              </Window>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
      <v-col v-if="panel" cols="3">
        <Panel
          @click:outside="panel = false"
          @add:element="addElement"
          :id="selectedId"
          :raw-html="rawHTML"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Panel from "@/components/Panel";
import WrapperElement from "@/components/elements/WrapperElement";
import Window from "@/components/Window";
export default {
  name: "Editor",
  components: {
    Window,
    WrapperElement,
    Panel,
  },
  computed: {
    rawHTML() {
      let el = null;
      this.$nextTick(() => {
        el = document.getElementById("content-container");
        console.log(el.innerHTML);
      });
      return el?.innerHTML;
    },
  },
  data() {
    return {
      sections: [
        {
          component: "Wrapper",
          children: [
            {
              component: "TextElement",
              data: {},
            },
            {
              component: "ButtonElement",
              data: {},
            },
          ],
        },
      ],
      panel: true,
      selectedId: "",
    };
  },
  methods: {
    addSection(id) {
      console.log(id);
      this.sections.push({
        component: "Wrapper",
        children: [],
      });
    },
    selectElement(id) {
      console.log(id);
      this.selectedId = id;
    },
    addElement(el) {
      let index = this.selectedId.split("-")[1];
      this.sections[index].children.push({
        component: el,
        data: {},
      });
    },
  },
};
</script>

<style scoped></style>
