<template>
  <div
    class="add-new-item-modal block-modal"
    id="newItemModal"
    :style="{ display: ShowSectionsModal, left: modalAttrs.xPosition, top: modalAttrs.yPosition }"
  >
    <div class="modal-item" @click="addBlock(100)">
      <span class="item-header">
        <div class="grid-size-xl"></div>
      </span>
      <span class="item-footer"> 100</span>
    </div>
    <div class="modal-item" @click="addBlock(75)">
      <span class="item-header">
        <div class="grid-size-lg"></div>
      </span>
      <span class="item-footer"> 75</span>
    </div>
    <div class="modal-item" @click="addBlock(50)">
      <span class="item-header">
        <div class="grid-size-md"></div>
      </span>
      <span class="item-footer"> 50</span>
    </div>
    <div class="modal-item" @click="addBlock(25)">
      <span class="item-header">
        <div class="grid-size-sm"></div>
      </span>
      <span class="item-footer"> 25</span>
    </div>
    <div class="modal-item grid-size-2xl">
      <p class="text-black">lorem epsum dollar smit</p>
    </div>
    <div class="modal-item">
      <span class="item-header"> </span>
      <span class="item-footer"> lorem</span>
    </div>
    <div class="modal-item">
      <span class="item-header"> </span>
      <span class="item-footer"> lorem</span>
    </div>
    <div class="modal-item">
      <span class="item-header"> </span>
      <span class="item-footer"> lorem</span>
    </div>
    <div class="modal-item">
      <span class="item-header"> </span>
      <span class="item-footer"> lorem</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Grids",
  components: {},
  data() {
    return {
      ShowSectionsModal: false,
      modalAttrs: {},
    };
  },
  created() {
    this.$root.$on("showSectionsModal", (event) => {
      this.ShowSectionsModal = event.showModal ? "flex" : "none";
      this.modalAttrs = {
        // xPosition: event.xPosition + "px",
        yPosition: event.yPosition + "px",
      };
    });
  },

  methods: {
    addBlock(size) {
      this.ShowSectionsModal = "none"
      this.$root.$emit("blockSize", size);
    }
  }
};
</script>
