<template>
  <div
    class="add-new-item-modal element-modal"
    id="itemModal"
    :style="{ top: modalAttrs.yPosition, left: modalAttrs.xPosition }"
    :class="ShowElementsModal ? 'active' : ''"
  >
    <div class="modal-item" @click="addElement('text', 'element_text')">
      <span class="item-header">
        <img
          class="before"
          :src="require('@/assets/images/svg/Group162.svg')"
          alt=""
        />
        <img
          class="after"
          :src="require('@/assets/images/svg/Group162white.svg')"
          alt=""
        />
      </span>
      <span class="item-footer">テキスト</span>
    </div>
    <div class="modal-item" @click="addElement('img', 'element_img')">
      <span class="item-header">
        <img
          class="before"
          :src="require('@/assets/images/svg/Path208.svg')"
          alt=""
        />
        <img
          class="after"
          :src="require('@/assets/images/svg/Path208white.svg')"
          alt=""
        />
      </span>
      <span class="item-footer">画像</span>
    </div>
    <div class="modal-item" @click="addElement('movie', 'element_movie')">
      <span class="item-header">
        <img
          class="before"
          :src="require('@/assets/images/svg/Group164.svg')"
          alt=""
        />
        <img
          class="after"
          :src="require('@/assets/images/svg/Group164white.svg')"
          alt=""
        />
      </span>
      <span class="item-footer">動画</span>
    </div>
    <div class="modal-item" @click="addElement('heading', 'element_heading')">
      <span class="item-header">
        <img
          class="before"
          :src="require('@/assets/images/svg/Path260.svg')"
          alt=""
        />
        <img
          class="after"
          :src="require('@/assets/images/svg/Path260white.svg')"
          alt=""
        />
      </span>
      <span class="item-footer">見出し</span>
    </div>
    <div class="modal-item" @click="addElement('margin', 'element_margin')">
      <span class="item-header">
        <img
          class="before"
          :src="require('@/assets/images/svg/Group163.svg')"
          alt=""
        />
        <img
          class="after"
          :src="require('@/assets/images/svg/Group163white.svg')"
          alt=""
        />
      </span>
      <span class="item-footer">余白</span>
    </div>
    <div class="modal-item" @click="addElement('chapter', 'element_chapter')">
      <span class="item-header">
        <img
          class="before"
          :src="require('@/assets/images/svg/textpara.svg')"
          alt=""
        />
        <img
          class="after"
          :src="require('@/assets/images/svg/textparawhite.svg')"
          alt=""
        />
      </span>
      <span class="item-footer">チャプターバー</span>
    </div>
    <div class="modal-item" @click="addElement('review', 'element_review')">
      <span
        class="item-header"
        style="display: flex; flex-direction: column; align-items: center"
      >
        <img
          class="before"
          style="margin-top: -10px"
          :src="require('@/assets/images/svg/Group161.svg')"
          alt=""
        />
        <img
          class="after"
          style="margin-top: -10px"
          :src="require('@/assets/images/svg/Group161white.svg')"
          alt=""
        />
        <div class="img-stars" style="margin-top: 5px">
          <img
            class="before"
            :src="require('@/assets/images/svg/Path220.svg')"
            alt=""
          />
          <img
            class="after"
            :src="require('@/assets/images/svg/Path220white.svg')"
            alt=""
          />
          <img
            class="before"
            :src="require('@/assets/images/svg/Path220.svg')"
            alt=""
          />
          <img
            class="after"
            :src="require('@/assets/images/svg/Path220white.svg')"
            alt=""
          />
          <img
            class="before"
            :src="require('@/assets/images/svg/Path220.svg')"
            alt=""
          />
          <img
            class="after"
            :src="require('@/assets/images/svg/Path220white.svg')"
            alt=""
          />
          <img
            class="before"
            :src="require('@/assets/images/svg/Path220.svg')"
            alt=""
          />
          <img
            class="after"
            :src="require('@/assets/images/svg/Path220white.svg')"
            alt=""
          />
          <img
            class="before"
            :src="require('@/assets/images/svg/Path220.svg')"
            alt=""
          />
          <img
            class="after"
            :src="require('@/assets/images/svg/Path220white.svg')"
            alt=""
          />
        </div>
      </span>
      <span class="item-footer">レビュー</span>
    </div>
    <div class="modal-item" @click="addElement('contactform', 'element_contactform')">
      <span class="item-header">
        <img
          class="before"
          :src="require('@/assets/images/svg/Group170.svg')"
          alt=""
        />
        <img
          class="after"
          :src="require('@/assets/images/svg/Group170white.svg')"
          alt=""
        />
      </span>
      <span class="item-footer">フォーム</span>
    </div>
    <div class="modal-item" @click="addElement('button', 'element_button')">
      <span class="item-header">
        <img
          class="before"
          :src="require('@/assets/images/svg/Group169.svg')"
          alt=""
        />
        <img
          class="after"
          :src="require('@/assets/images/svg/Group169white.svg')"
          alt=""
        />
      </span>
      <span class="item-footer">ボタン</span>
    </div>
    <div class="modal-item" @click="addElement('countdown', 'element_countdown')">
      <span class="item-header">
        <img
          class="before"
          :src="require('@/assets/images/svg/Group165.svg')"
          alt=""
        />
        <img
          class="after"
          :src="require('@/assets/images/svg/Group165white.svg')"
          alt=""
        />
      </span>
      <span class="item-footer">カウントダウン</span>
    </div>
    <div class="modal-item" @click="addElement('selectplans', 'element_selectplans')">
      <span class="item-header">
        <div style="margin: 0 auto">
          <img
            class="before"
            :src="require('@/assets/images/svg/Rectangle220.svg')"
            alt=""
          />
          <img
            class="after"
            :src="require('@/assets/images/svg/Rectangle220white.svg')"
            alt=""
          />
          <img
            class="before"
            :src="require('@/assets/images/svg/Rectangle221.svg')"
            alt=""
          />
          <img
            class="after"
            :src="require('@/assets/images/svg/Rectangle221white.svg')"
            alt=""
          />
        </div>
      </span>
      <span class="item-footer">二者択一</span>
    </div>
    <div class="modal-item" @click="addElement('stripepg', 'element_stripepg')">
      <span class="item-header">
        <img
          class="before"
          :src="require('@/assets/images/svg/Group150.svg')"
          alt=""
        />
        <img
          class="after"
          :src="require('@/assets/images/svg/Group150white.svg')"
          alt=""
        />
      </span>
      <span class="item-footer">カード</span>
    </div>
    <div class="modal-item" @click="addElement('optionitem', 'element_optionitem')">
      <span class="item-header">
        <img
          class="before"
          :src="require('@/assets/images/svg/Group153.svg')"
          alt=""
        />
        <img
          class="after"
          :src="require('@/assets/images/svg/Group153white.svg')"
          alt=""
        />
      </span>
      <span class="item-footer">追加注文</span>
    </div>
    <div class="modal-item" @click="addElement('viewcart', 'element_viewcart')">
      <span class="item-header">
        <img
          class="before"
          :src="require('@/assets/images/svg/Group152.svg')"
          alt=""
        />
        <img
          class="after"
          :src="require('@/assets/images/svg/Group152white.svg')"
          alt=""
        />
      </span>
      <span class="item-footer">お会計</span>
    </div>
    <div class="modal-item" @click="addElement('productlist', 'element_productlist')">
      <span class="item-header">
        <img
          class="before"
          :src="require('@/assets/images/svg/Path251.svg')"
          alt=""
        />
        <img
          class="after"
          :src="require('@/assets/images/svg/Path251white.svg')"
          alt=""
        />
      </span>
      <span class="item-footer">商品選択</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "ElementsModal",
  components: {},
  data() {
    return {
      ShowElementsModal: false,
      modalAttrs: {},
    };
  },
  methods: {
    addElement(type, placeholder) {
      this.ShowElementsModal = !this.ShowElementsModal
      let element = {
        elementType: type,
        elementPlaceholder: placeholder
      }
      this.$root.$emit("addElement", element);
    }
  },
  created() {
    this.$root.$on("showElementsModal", (event) => {
      this.ShowElementsModal = event.showModal;
      this.modalAttrs = {
        // xPosition: event.xPosition + "px",
        yPosition: event.yPosition + "px",
      };
    });
  },
};
</script>
