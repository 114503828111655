<template>
<v-card class="pa-5">
    <v-toolbar class="align-end elevation-0">
        <v-toolbar-title class="font-weight-bold align-end">商品一覧 </v-toolbar-title>
        <v-spacer></v-spacer>
        
        <v-btn depressed outlined medium bottom color="grey-dark align-end" class="btn-default mx-2 dublicate-btn">
            複製
        </v-btn>
        <v-btn depressed outlined medium @click="modal = true" color="grey-dark" class="btn-default mx-2 delete-btn">
            削除
        </v-btn>
        <v-btn depressed outlined medium color="white" class="btn-primary mx-2 text-button addproduct-btn">
            + 商品を追加する
        </v-btn>
    </v-toolbar>
    <v-container class="pt-3" id="table2">
        <v-data-table
        :headers="data2.headers"
        :items="data2.desserts"
        class="elevation-0"
        hide-default-footer
        >
        <template v-slot:[`item.check`]="{ item }">
            <v-simple-checkbox
            v-model="item.check"
            ></v-simple-checkbox>
        </template>
        
        </v-data-table>
    </v-container>
    <template>
        <div class="comfrim-modal">
            <v-dialog
            v-model="modal"
            max-width="400px"
            content-class="confirm-dialog"
            >
            <v-card class="py-3">
                <v-card-title class="justify-center">
                <span class="text-md-h6 mb-2">こちらを本当に削除しますか？</span>
                </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn depressed outlined medium @click="modal = false" color="#00A497" class="btn-default mr-2 text-button">
                    キャンセル
                    </v-btn>
                    <v-btn depressed outlined medium @click="modal = false"  color="white" class="btn-seconday px-10 text-button" >
                    OK
                    </v-btn>
                </v-card-actions>
            </v-card>
            </v-dialog>
        </div>
    </template>
</v-card>
</template>

<script>

export default {
  name: "ProductListTable",
  components: {  },
  computed: {},
  data: () => ({
      modal:false,
    data2: {
        pagination: {
        sortBy: 'name',
        },
        selected: [],
        headers: [
            { text: '選択', value:'check', sortable: false, width:'1rem' },
            { text: '商品名', align: 'left', sortable: false, value: 'name', cellClass:"font-weight-bold primary-color pointer"},
            { text: '購入者数', align: 'center', sortable: false, value: 'user_count', width:'6rem', cellClass:'font-weight-bold' },
            { text: '累計売上', align: 'center', sortable: false, value: 'avg_cart_value', width:'8rem', cellClass:'font-weight-bold'},
        ],
        desserts: [
        {
            check: false,
            name: 'タルムードA',
            user_count: '6,700名',
            avg_cart_value: '1,670,000円',
        },
        {
            check: false,
            name: 'タルムードA',
            user_count: '6,700名',
            avg_cart_value: '1,670,000円',
        },
        {
            check: false,
            name: 'タルムードA',
            user_count: '6,700名',
            avg_cart_value: '1,670,000円',
        },
        {
            check: false,
            name: 'タルムードA',
            user_count: '6,700名',
            avg_cart_value: '1,670,000円',
        },
        {
            check: false,
            name: 'タルムードA',
            user_count: '6,700名',
            avg_cart_value: '1,670,000円',
        },
        {
            check: false,
            name: 'タルムードA',
            user_count: '6,700名',
            avg_cart_value: '1,670,000円',
        },
        {
            check: false,
            name: 'タルムードA',
            user_count: '6,700名',
            avg_cart_value: '1,670,000円',
        },
        {
            check: false,
            name: 'タルムードA',
            user_count: '6,700名',
            avg_cart_value: '1,670,000円',
        },
        {
            check: false,
            name: 'タルムードA',
            user_count: '6,700名',
            avg_cart_value: '1,670,000円',
        },
        {
            check: false,
            name: 'タルムードA',
            user_count: '6,700名',
            avg_cart_value: '1,670,000円',
        },

        ]
    }
  }),

  methods: {
    
  }
};
</script>

<style scoped lang="scss">
>>>.confirm-dialog {
    align-self: flex-start;
    margin: 0px;
  }
  .dublicate-btn{
      border-radius: 5px;
  }
  .delete-btn{
      border-radius: 5px;
  }
  .addproduct-btn{
      border-radius: 5px;
  }
</style>

