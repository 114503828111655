<template>
  <div>
    Homee
    <Editor />
  </div>
</template>

<script>
import Editor from "@/components/Editor";
export default {
  name: "Home",

  components: { Editor },
};
</script>
