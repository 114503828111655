<template>
  <div>Test Text</div>
</template>

<script>
export default {
  name: "TextElement",
  props: {
    data: Object,
  },
};
</script>

<style scoped></style>
