<template>
  <v-container>
    <v-row>
      <v-col cols="12"> Available Elements </v-col>
      <v-col cols="12" v-if="id"> Adding new element to {{ id }} </v-col>
      <v-col
        @click="$emit('add:element', element)"
        v-for="(element, index) in elements"
        :key="`element-${index}`"
        cols="4"
        class="element-container mx-2 my-2"
      >
        <div class="text-center">
          {{ element }}
        </div>
      </v-col>
      <v-col cols="12">
        RAW HTML:
        {{ rawHTML }}
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Panel",
  props: {
    visible: Boolean,
    id: String,
    rawHTML: String,
  },
  data() {
    return {
      elements: ["TextElement", "ButtonElement"],
    };
  },
};
</script>

<style lang="scss" scoped>
.element-container {
  border: 2px gray solid;
  border-radius: 8px;
  cursor: pointer;
}
</style>
