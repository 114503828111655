<template>
  <div class="py-4">
    <v-btn
      block
      color="gray"
      text
      :ripple="false"
      @click="$emit('select:element')"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "EmptyElement",
};
</script>

<style scoped></style>
