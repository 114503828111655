<template>
  <div class="page-sections" :class="view">
    <div class="block-main">
      <div
        class="block-inr mb-5"
        v-for="(block, blockIndex) in blocks"
        :key="blockIndex"
        :style="{ width: block.width }"
      >
        <span
          class="add-cm add-block"
          @click="showBlockModal(block.blockId, blockIndex, $event)"
        >
          <i class="fas fa-plus"> </i>
        </span>
        <div class="block-tools">
          <div class="block-up-down">
            <ul>
              <li>
                <i class="fas fa-caret-up tool">
                  <span class="tooltip-text">上</span>
                </i>
              </li>
              <li>
                <i class="fas fa-caret-down tool">
                  <span class="tooltip-text">下</span>
                </i>
              </li>
            </ul>
          </div>
          <span class="block-option">
            <ul>
              <li>
                <i
                  @click="moveTop(blockIndex)"
                  class="fas fa-caret-up tool-icon tool"
                >
                  <span class="tooltip-text">上 </span></i
                >
            </li>
            <li>
              <i
                @click="moveDown(blockIndex)"
                class="fas fa-caret-down tool-icon tool"
                ><span class="tooltip-text">下 </span></i
              >
            </li>
            <li>
              <i class="fas fa-cog tool-icon tool"
                ><span class="tooltip-text">設定</span></i
              >
            </li>
            <li>
              <i class="far fa-file-alt tool-icon tool"
                ><span class="tooltip-text">保存</span></i
              >
            </li>
            <li>
              <i
                class="far fa-file tool-icon tool"
                @click="duplicateBlock(blockIndex, block.blockId, $event)"
                ><span class="tooltip-text">複製</span></i
              >
            </li>
            <li>
              <i
                class="far fa-trash-alt tool-icon tool"
                @click="removeBlock(blockIndex, 'block', $event)"
                ><span class="tooltip-text">削除</span></i
              >
            </li>
            </ul>
          </span>
        </div>
        <!-- <div class="form-content">
          <FormInputElement />
          <FormInputElement :alowRowAddition="true" />
          <FormInputElement />
          
        </div> -->
        <!-- <Blocks /> -->

        <!-- Rows -->
        <div
          class="new-block"
          v-if="block.newRow"
          @click="showRowsModal(0, block.blockId, 0, $event)"
        >
          <i class="fas fa-plus"> </i>
        </div>

        <div class="section-main mb-5 mt-5" v-else>
          <div class="section-inr" v-for="(row, index) in block.rows" :key="index">
            <v-row>
              <div class="section-up-down">
                <ul>
                  <li>
                    <i class="fas fa-caret-up tool">
                      <span class="tooltip-text">上</span>
                    </i>
                  </li>
                  <li>
                    <i class="fas fa-caret-down tool">
                      <span class="tooltip-text">下</span>
                    </i>
                  </li>
                </ul>
              </div>
              <span class="section-tools">
                <ul>
                  <li>
                <i
                  @click="moveTop(blockIndex)"
                  class="fas fa-caret-up tool-icon tool"
                >
                  <span class="tooltip-text">上 </span></i
                >
                </li>
                <li>
                <i
                  @click="moveDown(blockIndex)"
                  class="fas fa-caret-down tool-icon tool"
                  ><span class="tooltip-text">下 </span></i
                >
                </li>
                <li>
                <i class="fas fa-cog tool-icon tool"
                  ><span class="tooltip-text">設定</span></i
                >
                </li>
                <li>
                <i
                  class="far fa-file tool-icon tool"
                  @click="
                    duplicateRow(
                      blockIndex,
                      block.blockId,
                      row.rowId,
                      index,
                      $event
                    )
                  "
                  ><span class="tooltip-text">複製</span></i
                >
                </li>
                <li>
                <i
                  class="far fa-trash-alt tool-icon tool"
                  @click="removeRow(blockIndex, index, $event)"
                  ><span class="tooltip-text">削除</span></i
                >
                </li>
                </ul>
              </span>
              <v-col
                class="text-center section-items"
                v-for="(grid, i) in row.columns"
                :key="i"
                :md="grid.colGrid"
              >
                <draggable
                  :list="grid.elements"
                  :group="{ name: 'row' }"
                  class="element"
                  ghost-class="ghost"
                  draggable=".elements"
                  @end="onDragEnd"
                  @start="onStart"
                >
                  <div
                    class="new-row"
                    v-if="grid.elements.length == 0"
                    @click="
                      showElementModal(
                        block.blockId,
                        row.rowId,
                        grid.columnId,
                        'newElement',
                        $event
                      )
                    "
                  >
                    <h1>{{ grid.elementPlaceholder }}</h1>
                    <i class="fas fa-plus"> </i>
                  </div>

                  <div
                    v-else
                    v-for="(element, keys) in grid.elements"
                    :key="keys"
                    class="elements"
                  >
                    <h1>{{ element.elementPlaceholder }}</h1>
                    <div
                      class="add-cm add-element"
                      @click="
                        showElementModal(
                          block.blockId,
                          row.rowId,
                          grid.columnId,
                          keys,
                          $event
                        )
                      "
                    >
                      <i class="fas fa-plus"> </i>
                    </div>
                    <div class="form-control-resize">
                      <img :src="require('@/assets/images/resizeIcon.svg')" />
                    </div>

                    <span class="element-tools">
                      <ul>
                        <li>
                          <i class="fas fa-arrows-alt tool-icon tool">
                            <span class="tooltip-text">移動</span>
                          </i>
                      </li>
                      <li>
                        <i class="fas fa-cog tool-icon tool"
                          ><span class="tooltip-text">設定</span></i
                        >
                      </li>
                      <li>
                        <i class="far fa-file tool-icon tool"
                          ><span class="tooltip-text">複製</span></i
                        >
                      </li>
                      <li>
                        <i
                          class="far fa-trash-alt tool-icon tool"
                          @click="removeRow(blockIndex, index, $event)"
                          ><span class="tooltip-text">削除</span></i
                        >
                      </li>
                      </ul>
                    </span>
                  </div>
                </draggable>
              </v-col>
            </v-row>
            <div
              class="add-cm add-section"
              @click="showRowsModal(row.rowId, block.blockId, index, $event)"
            >
              <i class="fas fa-plus" :id="row.rowId"> </i>
            </div>
          </div>

          <RowModal />
        </div>

        <!-- End Rows -->
      </div>

      <Grids />
    </div>

    <ElementsModal />
    <DeleteModal :modal="confirmDelete" />
  </div>
</template>

<script>
import draggable from "vuedraggable";
// import FormInputElement from "@/components/configuration/FormInputElement";
import Grids from "@/components/configuration/Grids";
import RowModal from "@/components/configuration/RowModal";
import ElementsModal from "@/components/configuration/ElementsModal";
import DeleteModal from "@/components/ui/DeleteModal";

// import Blocks from "@/components/configuration/Blocks";

export default {
  name: "createTemplate",
  components: {
    // FormInputElement,
    DeleteModal,
    ElementsModal,
    Grids,
    RowModal,
    draggable,
  },
  props: {
    view: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showsectionModal: false,
      selectedRowId: 0,
      selectedBlockId: 0,
      selectedColumnId: 0,
      selectedRowIndex: null,
      selectedBlockIndex: null,
      selectedElementIndex: null,
      confirmDelete: false,
      deleteType: null,
      blocks: [
        {
          blockId: 1,
          width: "100%",
          newRow: false,
          rows: [
            {
              rowId: 1,
              columns: [
                {
                  columnId: 1,
                  colGrid: 12,
                  elements: [
                    {
                      elementType: "text",
                      elementPlaceholder: "element_text",
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    };
  },
  methods: {
    showBlockModal: function (blockId, blockIndex, event) {
      var item = event.target;
      this.selectedBlockId = event.target.id;
      this.selectedBlockIndex = blockIndex;
      // console.log(blockId);
      item.style.zIndex = "111111";
      var clientX = Math.ceil(item.getBoundingClientRect().x);
      var clientY = Math.ceil(item.getBoundingClientRect().y + 10);
      if (clientX < 300) {
        clientX = 0;
      } else {
        clientX = clientX - 480;
      }
      this.showsectionModal = !this.showsectionModal;
      this.modalAttrs = {
        xPosition: clientX,
        yPosition: clientY,
        showModal: this.showsectionModal,
      };

      this.$root.$emit("showSectionsModal", this.modalAttrs);
    },

    showRowsModal: function (rowId, blockId, index, event) {
      this.selectedRowId = rowId;
      this.selectedBlockId = blockId;
      this.selectedRowIndex = index;
      this.showElementsModal = false;
      var item = event.target;
      // this.selectedRowId = event.target.id;
      item.style.zIndex = "111111";
      var clientX = Math.ceil(item.getBoundingClientRect().x);
      var clientY = Math.ceil(item.getBoundingClientRect().y + 70);
      if (clientX < 300) {
        clientX = 0;
      } else {
        clientX = clientX - 280;
      }
      this.showRowModal = !this.showRowModal;
      this.modalAttrs = {
        xPosition: clientX,
        yPosition: clientY - 57,
        showModal: this.showRowModal,
      };

      this.$root.$emit("showRowsModal", this.modalAttrs);
      this.showRowModal = !this.showRowModal;
    },

    showElementModal: function (blockId, rowId, columnId, elementIndex, event) {
      // this.showRowModal = false;
      this.selectedRowId = rowId;
      this.selectedBlockId = blockId;
      this.selectedColumnId = columnId;
      this.selectedElementIndex = elementIndex;
      var item = event.target;
      item.style.zIndex = "111111";
      var clientX = Math.ceil(item.getBoundingClientRect().x);
      var clientY = Math.ceil(item.getBoundingClientRect().y + 10);
      if (clientX < 300) {
        clientX = 0;
      } else {
        clientX = clientX - 380;
      }
      this.showElementsModal = !this.showElementsModal;
      this.modalAttrs = {
        xPosition: clientX,
        yPosition: clientY,
        showModal: this.showElementsModal,
      };

      this.$root.$emit("showElementsModal", this.modalAttrs);
    },

    moveTop: function (index) {
      if (this.blocks[index - 1])
        this.blocks.splice(
          index - 1,
          2,
          this.blocks[index],
          this.blocks[index - 1]
        );
    },

    moveDown: function (index) {
      if (this.blocks[index + 1])
        this.blocks.splice(
          index,
          2,
          this.blocks[index + 1],
          this.blocks[index]
        );
    },

    onDragEnd: function () {},
    onStart: function () {},

    removeBlock: function (index, deleteType) {
      this.deleteType = deleteType;
      this.selectedBlockIndex = index;
      this.confirmDelete = true;
      // this.blocks.splice(index, 1);
    },

    removeRow: function (blockIndex, rowIndex, deleteType) {
      this.deleteType = deleteType;
      this.selectedBlockIndex = blockIndex;
      this.selectedRowIndex = rowIndex;
      this.confirmDelete = true;
      // this.blocks[blockIndex].rows.splice(rowIndex, 1);
    },

    duplicateBlock: function (blockIndex, blockId) {
      var currentBlock = this.blocks.find((i) => i.blockId == blockId);
      this.blocks.splice(blockIndex + 1, 0, currentBlock);
      this.blocks[blockIndex + 1].blockId = Math.random()
        .toString(36)
        .substr(2, 6);
    },

    duplicateRow: function (blockIndex, blockId, rowId, rowIndex) {
      var currentRow = this.blocks
        .find((i) => i.blockId == blockId)
        .rows.find((r) => r.rowId == rowId);
      // currentBlock.blockId = Math.random().toString(36).substr(2, 6);
      this.blocks[blockIndex].rows.splice(rowIndex + 1, 0, currentRow);
    },
  },

  created() {
    this.$root.$on("addRow", (cols) => {
      if (cols == 1) {
        this.cols = 12;
      }
      if (cols == 2) {
        this.cols = 6;
      }
      if (cols == 3) {
        this.cols = 4;
      }
      let columns = [];
      for (let i = 0; i < cols; i++) {
        columns.push({
          columnId: Math.random().toString(36).substr(2, 6),
          colGrid: this.cols,
          elements: [],
        });
      }

      // this.selectedRowId = Number(this.selectedRowId) + 1;
      let currentBlock = this.blocks.find(
        (i) => i.blockId == this.selectedBlockId
      );
      // let currentRow = currentBlock.find(
      //   (i) => i.rowId == this.selectedRowId
      // );
      currentBlock.newRow = false;
      // currentRow.newColumn = false;
      this.selectedRowIndex = this.selectedRowIndex + 1;
      this.$nextTick(() => {
        currentBlock.rows.splice(this.selectedRowIndex, 0, {
          rowId: Math.random().toString(36).substr(2, 6),
          columns: columns,
        });
      });
    });

    this.$root.$on("blockSize", (size) => {
      this.selectedBlockIndex = this.selectedBlockIndex + 1;
      let block = {
        blockId: Math.random().toString(36).substr(2, 6),
        width: size + "%",
        newRow: true,
        rows: [],
      };
      this.blocks.splice(this.selectedBlockIndex, 0, block);
      // this.$store.commit('addHistory', {
      //   type: 'blockAdded',
      //   items: block
      // })

      this.$store.commit("addCoords", {
        type: "blockAdded",
        items: block,
      });
      console.log(this.$store.state.coords);
      // this.undone = [];
    });

    this.$root.$on("addElement", (element) => {
      this.showElementsModal = !this.showElementsModal;
      this.modalAttrs = {
        xPosition: 0,
        yPosition: 0,
        showModal: this.showElementsModal,
      };

      this.$root.$emit("showElementsModal", this.modalAttrs);

      let currentColumn = this.blocks
        .find((i) => i.blockId == this.selectedBlockId)
        .rows.find((r) => r.rowId == this.selectedRowId)
        .columns.find((c) => c.columnId == this.selectedColumnId);
      if (this.selectedElementIndex == "newElement") {
        currentColumn.elements.push(element);
      } else {
        this.$nextTick(() => {
          currentColumn.elements.splice(
            this.selectedElementIndex + 1,
            0,
            element
          );
        });
      }
    });

    this.$root.$on("deleteElement", (deleteElement) => {
      if (deleteElement) {
        if (this.deleteType == "row") {
          this.blocks[this.selectedBlockIndex].rows.splice(
            this.selectedRowIndex,
            1
          );
        } else if (this.deleteType == "block") {
          this.blocks.splice(this.selectedBlockIndex, 1);
        }
      }
      this.confirmDelete = false;
    });

    this.$root.$on("undo", () => {
      this.undo();
      console.log(this.$store.state.coords);
    });

    this.$root.$on("redo", () => {
      this.redo();
      console.log(this.$store.state.coords);
    });
  },
};
</script>
