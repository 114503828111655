<template>
  <div class="header-rgt">
    <v-btn depressed color="#fff" width="111" height="35" min-width="111" class="preview-blk">
      <v-icon color="#00A497">mdi-eye</v-icon>
      <span class="ml-1">プレビュー</span>
    </v-btn>
    <v-btn depressed color="#fff" width="111" height="35" min-width="111" class="ml-1 save-blk">
      <v-icon color="#00A497">mdi-content-save</v-icon>
      <span class="ml-1"> 保存 </span>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "Save",
  components: {},
  computed: {},
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.v-application{
  header{
    button{
      border-radius: 2px;
    }
  }
  .preview-blk{
    span{
      color: #00A497;
    }
  }
  .save-blk{
    span{
      color: #00A497;
    }
  }
}
</style>
