<template>
  <section class="rgb-section" :class="toggleRgb ? 'active' : ''">
    <div class="rgb-section-header">
      <!-- <img src="./assets/images/img/1.jpg" width="37px" alt="" />
      <img src="./assets/images/img/2.jpg" width="37px" alt="" />
      <img src="./assets/images/img/3.jpg" width="37px" alt="" />
      <img src="./assets/images/img/4.jpg" width="37px" alt="" />
      <img src="./assets/images/img/5.jpg" width="37px" alt="" /> -->
    </div>
    <div class="rgb-header">
      <select class="rgb-dropdown" @change="changePickerType">
        <option value="bar">RGBつまみ</option>
        <option value="circle" selected>色相環</option>
        <option value="history">ヒストリー</option>
      </select>
      <div class="rgb-more-btn">
        <i class="far fa-ellipsis-h"></i>
      </div>
    </div>
    <!-- History Items  -->
    <div class="rgb-history-items" v-if="pickerType == 'history'">
      <ul class="rgb-history-list">
        <li>
          <button class="rgb-history-item" @click="pickHistoryColor">
            <div class="history-color h-color1"></div>
            青色
          </button>
        </li>
        <li>
          <button class="rgb-history-item" @click="pickHistoryColor">
            <div class="history-color h-color2"></div>
            イロアスブルー
          </button>
        </li>
        <li>
          <button class="rgb-history-item" @click="pickHistoryColor">
            <div class="history-color h-color3"></div>
            ライトブルー
          </button>
        </li>
        <li>
          <button class="rgb-history-item" @click="pickHistoryColor">
            <div class="history-color h-color4"></div>
            グレー
          </button>
        </li>
        <li>
          <button class="rgb-history-item" @click="pickHistoryColor">
            <div class="history-color h-color5"></div>
            青
          </button>
        </li>
        <li>
          <button class="rgb-history-item" @click="pickHistoryColor">
            <div class="history-color h-color6"></div>
            赤
          </button>
        </li>
        <li>
          <button class="rgb-history-item" @click="pickHistoryColor">
            <div class="history-color h-color7"></div>
            紫の
          </button>
        </li>
        <li>
          <button class="rgb-history-item" @click="pickHistoryColor">
            <div class="history-color h-color8"></div>
            青色
          </button>
        </li>
        <li>
          <button class="rgb-history-item" @click="pickHistoryColor">
            <div class="history-color h-color9"></div>
            青色
          </button>
        </li>
        <li>
          <button class="rgb-history-item" @click="pickHistoryColor">
            <div class="history-color h-color10"></div>
            青色
          </button>
        </li>
        <li>
          <button class="rgb-history-item" @click="pickHistoryColor">
            <div class="history-color h-color11"></div>
            青色
          </button>
        </li>
        <li>
          <button class="rgb-history-item" @click="pickHistoryColor">
            <div class="history-color h-color12"></div>
            青色
          </button>
        </li>
        <li>
          <button class="rgb-history-item" @click="pickHistoryColor">
            <div class="history-color h-color13"></div>
            青色
          </button>
        </li>
        <li>
          <button class="rgb-history-item" @click="pickHistoryColor">
            <div class="history-color h-color14"></div>
            青色
          </button>
        </li>
        <li>
          <button class="rgb-history-item" @click="pickHistoryColor">
            <div class="history-color h-color15"></div>
            青色
          </button>
        </li>
        <li>
          <button class="rgb-history-item" @click="pickHistoryColor">
            <div class="history-color h-color16"></div>
            青色
          </button>
        </li>
        <li>
          <button class="rgb-history-item" @click="pickHistoryColor">
            <div class="history-color h-color17"></div>
            青色
          </button>
        </li>
        <li>
          <button class="rgb-history-item" @click="pickHistoryColor">
            <div class="history-color h-color18"></div>
            青色
          </button>
        </li>
      </ul>
    </div>
    <!-- End History Items -->

    <!-- Bar -->
    <div class="wrapper" v-if="pickerType == 'bar'">
      <div class="main">
        <input type="text" id="box" value="rgb(255,255,255)" />

        <div class="color__wrap">
          <label for="">
            レッド:<input
              type="range"
              id="red"
              value="238"
              min="0"
              max="255"
              @input="changeSlider"
            />
          </label>
          <span id="redValue">238</span>
        </div>
        <div class="color__wrap">
          <label for="">
            グリーン:<input
              type="range"
              id="green"
              value="34"
              min="0"
              max="255"
              @input="changeSlider"
            />
          </label>
          <span id="greenValue">34</span>
        </div>
        <div class="color__wrap">
          <label for="">
            ブルー:<input
              type="range"
              id="blue"
              value="12"
              min="0"
              max="255"
              @input="changeSlider"
            />
          </label>
          <span id="blueValue">12</span>
        </div>
        <div class="rgbValue">
          <span>16進数カラー値</span>
          <span class="rgbToHEX">EE220C</span>
        </div>
      </div>
    </div>
    <!-- End Bar -->

    <!-- Circle -->
    <div class="circle-picker" v-if="pickerType == 'circle'">
      <div class="wheel" id="colorWheelDemo"></div>
    </div>
    <!-- End Circle -->

    <div class="rgb-footer-title">
      <div class="footer-text">不透明度</div>
      <div class="container--dark">
        <input
          class="rgb-footer-range"
          type="range"
          min="0"
          max="100"
          value="100"
          step="1"
          onmousemove="rangevalue3.value=value; myColour()"
        />
        <output id="rangevalue3">100</output>
      </div>
    </div>

    <div class="rgb-history-footer">
      <div class="rgb-selected-color"></div>
      <button class="rgb-selector">
        <i class="fad fa-eye-dropper"></i>
      </button>
      <div class="rgb-footer-group">
        <span></span><span></span><span></span><span></span><span></span
        ><span></span><span></span><span></span><span></span><span></span
        ><span></span><span></span><span></span><span></span><span></span>
        <span></span><span></span><span></span><span></span><span></span
        ><span></span><span></span><span></span><span></span><span></span
        ><span></span><span></span><span></span><span></span><span></span>
        <span></span><span></span><span></span><span></span><span></span
        ><span></span><span></span><span></span><span></span><span></span
        ><span></span><span></span><span></span><span></span><span></span>
      </div>
    </div>
  </section>
</template>

<script>
import iro from "@jaames/iro";
export default {
  name: "RgbSection",
  components: {},
  computed: {},
  data() {
    return {
      toggleRgb: false,
      element: "",
      pickerType: "circle",
    };
  },
  methods: {
    changePickerType(e) {
      this.pickerType = e.target.value;
      console.log(this.pickerType);
    },

    pickHistoryColor(e) {
      let this_this = this;
      let backgroundColor = window.getComputedStyle(
        e.target.querySelector(".history-color")
      ).backgroundColor;
      this_this.$root.$emit("color", backgroundColor);
    },

    rgbatohex(color) {
      {
        if (/^rgb/.test(color)) {
          const rgba = color.replace(/^rgba?\(|\s+|\)$/g, "").split(",");

          // rgb to hex
          // eslint-disable-next-line no-bitwise
          let hex = `#${(
            (1 << 24) +
            (parseInt(rgba[0], 10) << 16) +
            (parseInt(rgba[1], 10) << 8) +
            parseInt(rgba[2], 10)
          )
            .toString(16)
            .slice(1)}`;

          // added alpha param if exists
          if (rgba[4]) {
            const alpha = Math.round(0o1 * 255);
            const hexAlpha = (alpha + 0x10000)
              .toString(16)
              .substr(-2)
              .toUpperCase();
            hex += hexAlpha;
          }

          return hex;
        }
        return color;
      }
    },

    changeSlider() {
      const rgbText = document.querySelector(".rgbToHEX");
      const red = document.getElementById("red").value;
      const green = document.getElementById("green").value;
      const blue = document.getElementById("blue").value;
      document.querySelector("#redValue").innerHTML = red;
      document.querySelector("#greenValue").innerHTML = green;
      document.querySelector("#blueValue").innerHTML = blue;

      rgbText.style.backgroundColor = `rgba(${red}, ${green}, ${blue}, ${
        Number(document.querySelector("#rangevalue3").innerHTML || 100) / 100
      })`;

      rgbText.innerHTML = this.rgbatohex(
        `rgba(${red}, ${green}, ${blue}, ${
          Number(document.querySelector("#rangevalue3").innerHTML || 100) / 100
        })`
      );

      var color = this.rgbatohex(
        `rgba(${red}, ${green}, ${blue}, ${
          Number(document.querySelector("#rangevalue3").innerHTML || 100) / 100
        })`
      );

      this.$root.$emit("color", color);
    },
  },
  created() {
    this.$root.$on("showColorPicker", (event) => {
      this.toggleRgb = event;
    });

    const colorWheel = iro.ColorPicker("#colorWheelDemo", {
      width: 330,
    });

    let this_this = this;
    colorWheel.on("color:change", function (color) {
      this_this.$root.$emit("color", color.hexString);
    });
  },
};
</script>
