<template>
<v-app>
  <top-bar></top-bar>
  <v-main class="content-wrapper">
    <v-container class="page-container" fluid>
        <v-row>
            <v-col cols="12">
                <v-card>
                    <v-toolbar 
                    color="#00A497"
                    class="align-center elevation-1">
                        <v-row class="mt-4">
                            <v-col cols="12" md="4"></v-col>
                            <v-col cols="12" md="4">
                                <v-text-field 
                                v-model="searchTerm" 
                                background-color="white"
                                outlined 
                                height=12
                                dense
                                placeholder="サイト名" 
                                @input="filteredData">
                                </v-text-field>
                            </v-col>
                            <v-col cols="2" md="4"></v-col>
                        </v-row>
                    </v-toolbar>
                    <v-container style="width: 90%">
                        <div class="inline">
                            <div class="theme-list mt-5">
                                <v-row
                                    align="center"
                                    class="flex-nowrap ma-0"
                                >
                                <v-col
                                    v-for="(data,index) in themes"
                                    :key="index"
                                >
                                <v-row class="flex-nowrap">
                                    <v-card
                                        width="200"
                                        class="elevation-0"
                                    >
                                        <v-card-title
                                            class="justify-center font-weight-bold"
                                        >
                                            リード<v-icon>fa-chevron-down</v-icon>
                                        </v-card-title>
                                        <v-card>
                                        <v-icon
                                        medium
                                        color="#959595"
                                        @click="modal = true"
                                        class="dialog-close"
                                        >
                                        mdi-close
                                        </v-icon>
                                            <img src="@/assets/images/page-theme/theme-1.png" width="170" />
                                        </v-card>
                                        <v-card-subtitle
                                            class="text-center font-weight-bold "
                                        >100円タルムード
                                        </v-card-subtitle>
                                    </v-card>
                                    <div class="pt-5"> 
                                        <v-icon color="#00A497">fas fa-chevron-double-right</v-icon>
                                    </div>
                                </v-row>
                                </v-col>
                                </v-row>
                            </div>
                        </div>
                        <div class="theme_plus">
                        <v-icon
                        medium
                        color="#00A497"
                        @click="dialog = true"
                        >
                        fas fa-plus-circle
                        </v-icon>
                        </div>
                    </v-container>
                    <v-card-actions class="justify-center">
                        <v-btn depressed outlined medium color="white" class="btn-primary mx-2 px-16 text-button">
                            決定
                        </v-btn>
                    </v-card-actions>
                </v-card >
            </v-col>
        </v-row>
    </v-container>
    <template>
        <div class="text-center">
            <v-dialog
            v-model="dialog"
            width="60%"
            >
            <v-card>
                <v-toolbar class="elevation-0">
                    <v-select
                    v-model="selected"
                    :items="items"
                    item-text="name"
                    item-value="id"
                    solo
                    flat
                    dense
                    class="create-page-select elevation-0 font-weight-bold"
                    ></v-select>
                    <v-icon
                    medium
                    color="#00A497"
                    @click="dialog = false"
                    class="dialog-close"
                    >
                    mdi-close
                    </v-icon>
                </v-toolbar>

                    <v-row class="ma-5 overflow-y-auto" style="height: 350px">
                        <v-col 
                        v-for="(data,index) in themes"
                        :key="index"
                        cols="3"
                        
                        >
                            <v-card>
                                <div>
                                    <img src="@/assets/images/page-theme/theme-1.png" width="150" />
                                </div>
                                <v-card-subtitle
                                    class="text-center font-weight-bold"
                                >100円タルムード
                                </v-card-subtitle>
                            </v-card>
                        </v-col>
                    </v-row>

                <v-card-actions class="justify-center">
                    <v-btn depressed outlined medium color="white" class="btn-primary mx-2 px-16 text-button" >
                    決定
                    </v-btn>
                </v-card-actions>
            </v-card>
            </v-dialog>
        </div>
    </template>

    <template>
        <div class="comfrim-modal">
            <v-dialog
            v-model="modal"
            max-width="400px"
            content-class="confirm-dialog"
            >
            <v-card>
                <v-card-title>
                <span class="text-md-h6">こちらを本当に削除しますか？</span>
                </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn depressed outlined medium @click="modal = false" color="#00A497" class="btn-default mx-2 text-button text-md-h6">
                    キャンセル
                    </v-btn>
                    <v-btn depressed outlined medium @click="modal = false"  color="white" class="btn-seconday mx-2 px-10 text-button" >
                    OK
                    </v-btn>
                </v-card-actions>
            </v-card>
            </v-dialog>
        </div>
    </template>
    
  </v-main>
</v-app>
</template>

<script>
import TopBar from "@/components/configuration/Dashboard/TopBar";

export default {
    name: "CreateSite",
    data(){
        return {
            dialog: false,
            modal: false,
            items: [
                {id:'1',name:'アップセル'},
                {id:'2', name:'決済フォーム'}, 
                {id:'3', name:'リード'}, 
                {id:'4', name:'サンキュー'}, 
                {id:'5', name:'ウェビナー視聴'}, 
                {id:'6', name:'セールスページ'}],
            selected: {id:'1'},
            filteredData: null,
            searchTerm:'',
            themes:[
                {
                    title:'リード',
                    thumbnail: '@/assets/images/page-theme/theme-1.png',
                    subtitle: '100円タルムード'
                },
                {
                    title:'リード',
                    thumbnail: '@/assets/images/page-theme/theme-1.png',
                    subtitle: '100円タルムード'
                },
                {
                    title:'リード',
                    thumbnail: '@/assets/images/page-theme/theme-1.png',
                    subtitle: '100円タルムード'
                },
                {
                    title:'リード',
                    thumbnail: '@/assets/images/page-theme/theme-1.png',
                    subtitle: '100円タルムード'
                },
                {
                    title:'リード',
                    thumbnail: '@/assets/images/page-theme/theme-1.png',
                    subtitle: '100円タルムード'
                },
                {
                    title:'リード',
                    thumbnail: '@/assets/images/page-theme/theme-1.png',
                    subtitle: '100円タルムード'
                },
                {
                    title:'リード',
                    thumbnail: '@/assets/images/page-theme/theme-1.png',
                    subtitle: '100円タルムード'
                },

            ]
        } 
    },
    components: { TopBar },
};
</script>

<style scoped lang="scss">
.v-toolbar .v-input{
      width: 400px;
}
>>>.confirm-dialog {
    align-self: flex-start;
    margin: 0px;
  }
</style>
