<template>

<v-card class="pa-5">
    <v-toolbar class="align-end elevation-0">
        <v-toolbar-title class="font-weight-bold align-end">ランディングサイト一覧 </v-toolbar-title>
        <v-spacer></v-spacer>
        <div class="inline">
            <label class="select-label">並び替え：</label>
            <select class="custom-select">
                <option>平均カートバリュー</option>
                <option>サイト名</option>
                <option>登録者数</option>
                <option>最終更新日</option>
            </select>
        </div>
        <v-btn depressed outlined medium bottom color="grey-dark align-end" class="btn-default mx-2 dublicate-btn">
            複製
        </v-btn>
        <v-btn depressed outlined medium @click="modal = true" color="grey-dark" class="btn-default mx-2 delete-btn">
            削除
        </v-btn>
        <router-link to="/create-site" class="text-decoration-none">
            <v-btn depressed outlined medium color="white" class="btn-primary mx-2 text-button build-site-btn">
                + サイトを構築する
            </v-btn>
        </router-link>
        

    </v-toolbar>
    <v-container class="pt-3" >
        <v-data-table
        :headers="data1.headers"
        :items="data1.desserts"
        class="elevation-0"
        hide-default-footer
        >
        <template v-slot:[`item.check`]="{ item }">
            <v-simple-checkbox
            v-model="item.check"
            ></v-simple-checkbox>
        </template>
        
        </v-data-table>
    </v-container>
    <template>
        <div class="comfrim-modal">
            <v-dialog
            v-model="modal"
            max-width="400px"
            content-class="confirm-dialog"
            >
            <v-card class="py-3">
                <v-card-title class="justify-center">
                <span class="text-md-h6 mb-2">こちらを本当に削除しますか？</span>
                </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn depressed outlined medium @click="modal = false" color="#00A497" class="btn-default mr-2 text-button">
                    キャンセル
                    </v-btn>
                    <v-btn depressed outlined medium @click="modal = false"  color="white" class="btn-seconday px-10 text-button" >
                    OK
                    </v-btn>
                </v-card-actions>
            </v-card>
            </v-dialog>
        </div>
    </template>
</v-card>

</template>

<script>

export default {
  name: "LancingSiteListTable",
  components: {  },
  computed: {},
  data() {
    return{
        modal: false,
        data1: {
            pagination: {
            sortBy: 'name',
            },
            selected: [],
            headers: [
                { text: '選択', value:'check', sortable: false, width:'1rem' },
                { text: 'サイト名', align: 'left', sortable: false, value: 'name', cellClass:"font-weight-bold primary-color pointer"},
                { text: '登録者数', align: 'center', sortable: false, value: 'user_count', width:'6rem', cellClass:'font-weight-bold' },
                { text: '平均カートバリュー', align: 'center', sortable: false, value: 'avg_cart_value', width:'10rem', cellClass:'font-weight-bold'},
                { text: '最終更新日', align: 'center', sortable: false, value: 'updated_at', width:'10rem',cellClass:"font-weight-medium" },
            ],
            desserts: [
            {
                check: false,
                name: 'タルムード001',
                user_count: '9,000名',
                avg_cart_value: '6,850円',
                updated_at: '2021年12月31日',
            },
            {
                check: false,
                name: '孝経001',
                user_count: '9,000名',
                avg_cart_value: '6,850円',
                updated_at: '2021年7月12日',
            },
            {
                check: false,
                name: '孝経002',
                user_count: '9,000名',
                avg_cart_value: '6,850円',
                updated_at: '2021年7月12日',
            },
            {
                check: false,
                name: '孝経003',
                user_count: '9,000名',
                avg_cart_value: '6,850円',
                updated_at: '2021年7月12日',
            },
            {
                check: false,
                name: '孝経004',
                user_count: '9,000名',
                avg_cart_value: '6,850円',
                updated_at: '2021年7月12日',
            },
            {
                check: false,
                name: '孝経005',
                user_count: '9,000名',
                avg_cart_value: '6,850円',
                updated_at: '2021年7月12日',
            },
            {
                check: false,
                name: '孝経006',
                user_count: '9,000名',
                avg_cart_value: '6,850円',
                updated_at: '2021年7月12日',
            },
            {
                check: false,
                name: '孝経007',
                user_count: '9,000名',
                avg_cart_value: '6,850円',
                updated_at: '2021年7月12日',
            },
            {
                check: false,
                name: '孝経008',
                user_count: '9,000名',
                avg_cart_value: '6,850円',
                updated_at: '2021年7月12日',
            },
            {
                check: false,
                name: '孝経009',
                user_count: '9,000名',
                avg_cart_value: '6,850円',
                updated_at: '2021年7月12日',
            },
            {
                check: false,
                name: '孝経0010',
                user_count: '9,000名',
                avg_cart_value: '6,850円',
                updated_at: '2021年7月12日',
            },
            {
                check: false,
                name: '孝経0011',
                user_count: '9,000名',
                avg_cart_value: '6,850円',
                updated_at: '2021年7月12日',
            },
            {
                check: false,
                name: '孝経0012',
                user_count: '9,000名',
                avg_cart_value: '6,850円',
                updated_at: '2021年7月12日',
            },
            
            ]
        },
    }
  },

  methods: {
    
  }
};
</script>
<style scoped lang="scss">
>>>.confirm-dialog {
    align-self: flex-start;
    margin: 0px;
  }
  .dublicate-btn{
      border-radius: 5px;
  }
  .delete-btn{
      border-radius: 5px;
  }
  .build-site-btn{
      border-radius: 5px;
  }
</style>
