<template>
  <div class="sidebar__toggle" @click="togglle(!showSidbar)">
    <i id="toggle_icon" class="fal fa-angle-double-left"></i>
  </div>
</template>
<script>
export default {
  name: "TopBar",
  components: {},
  computed: {},
  data() {
    return {
      showSidbar: true,
    };
  },
  methods: {
    togglle() {
      this.showSidbar = !this.showSidbar;
      // alert(this.showSidbar)
      this.$root.$emit("SidebarToggleEvent", this.showSidbar);

      // return
      //   const sidebarButton = document.getElementsByClassName("sidebar__toggle")[0];
      //   const toggleIcon = document.querySelector("#toggle_icon");
      //   const rgbSection = document.querySelector(".rgb-section");
      //   if (sidebarButton) {
      //     sidebarButton.addEventListener("click", function () {
      //       rgbSection.classList.remove("active");
      //       const sidebar = document.getElementsByClassName("sidebar-section")[0];
      //       sidebar.classList.toggle("sidebar__show");
      //       if (window.innerWidth >= 1200) {
      //         sidebar.classList.toggle("sidebar__none");
      //       }
      //       if (sidebar.classList.contains("sidebar__show")) {
      //         toggleIcon.classList.remove("fa-angle-double-left");
      //         toggleIcon.classList.add("fa-angle-double-right");
      //       } else {
      //         toggleIcon.classList.remove("fa-angle-double-right");
      //         toggleIcon.classList.add("fa-angle-double-left");
      //       }
      //     });
      //   }
    },
  },
};
</script>
