<template>
  <v-btn depressed color="#fff" width="35" height="35" min-width="35" class="ml-1 redo-blk" @click="redo()">
    <v-icon color="#00A497" size="40">mdi-menu-right</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: "Redo",
  components: {},
  computed: {},
  data() {
    return {};
  },
  methods: {
    redo() {
      this.$root.$emit("redo", true);
    }
  },
};
</script>
