import Vue from "vue";
import Vuex from "vuex";
import VuexUndoRedo from "vuex-undo-redo";
Vue.use(Vuex);
Vue.use(VuexUndoRedo);

export default new Vuex.Store({
  state: {
    coords: []
  },
  mutations: {
    addCoords(state, payload) {
      state.coords.push(payload);
    },
    // .emptyState() is needed by VuexUndoRedo
    emptyState() {
      this.replaceState({ coords: [] });
    }
  }
});



