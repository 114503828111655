<template>
<v-app>
  <top-bar></top-bar>
  <v-main class="content-wrapper mt-8">
    <v-container class="page-container" fluid>
        <v-row >
          <v-col cols="7">
            <landing-site-list-table></landing-site-list-table>
          </v-col>
          <v-col cols="5">
            <product-list-table></product-list-table>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3">
              <v-card>
                  <recent-activities></recent-activities>
              </v-card>
          </v-col>
          <v-col cols="9">
              <v-card>
                <chart></chart>
              </v-card>
          </v-col>
        </v-row>
    </v-container>
  </v-main>
</v-app>
</template>

<script>
import TopBar from "@/components/configuration/Dashboard/TopBar";
import LandingSiteListTable from './LandingSiteListTable';
import ProductListTable from './ProductListTable';
import RecentActivities from './RecentActivities';
import Chart from './Chart';

export default {
  name: "createTemplate",

  components: { TopBar, LandingSiteListTable, ProductListTable, RecentActivities, Chart },
};
</script>
<style src="@/styles/dashboard.scss" lang="scss" />