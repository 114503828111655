<template>
  <v-app-bar app fixed height="55" color="#00A497" class="px-4">
    <div class="d-flex align-center header-lft">
      <router-link to="/" class="logo-blk mr-10">
        <v-icon color="#fff">mdi-home</v-icon>
        <span class="pl-2 mt-1">COMPANY</span>
      </router-link>
      <v-btn depressed color="#fff" width="35" height="35" min-width="35" class="back-btn">
          <v-icon color="#00A497">mdi-open-in-new</v-icon>
      </v-btn>
      <SeoSettings />
      <Undo />
      <Redo />

      <v-btn depressed color="#fff" width="35" height="35" min-width="35" @click="_view = 'desktop'" :class="_view == 'desktop' ? 'active' : ''" class="ml-1 view-mode laptop-view">
          <v-icon color="#00A497">mdi-laptop</v-icon>
      </v-btn>

      <v-btn depressed color="#fff" width="35" height="35" min-width="35" @click="_view = 'mobile'" :class="_view == 'mobile' ? 'active' : ''" class="ml-1 view-mode mobile-view">
          <v-icon color="#00A497">mdi-cellphone</v-icon>
      </v-btn>
    </div>
    <v-spacer></v-spacer>
    <Save />
</v-app-bar>
</template>

<script>
import SeoSettings from "@/components/configuration/SeoSettings";
import Undo from "@/components/configuration/Undo";
import Redo from "@/components/configuration/Redo";
import Save from "@/components/configuration/Save";
export default {
  name: "TopBar",
  props: {
    view: {
      type: String,
      required: true,
    }
  },
  components: { SeoSettings, Undo, Redo, Save },
  computed: {
    _view: {
      get() {
        return this.view
      },
      set(value) {
        this.$emit('update:view', value)
      }
    }
  },
  data() {
    return {};
  },
};
</script>

<style scoped lang="scss">
.v-application{
  a{
    text-decoration: none;
  }
  header{
    button{
      border-radius: 2px;
    }
  }
  .logo-blk{
    color: #fff;
    display: flex;
    align-items: center;
    span{
      color: #fff;
    }
  }
  .view-mode.active{
    background-color: #707070 !important;
    i{
      color: #fff !important;
    }
  }
}
</style>
