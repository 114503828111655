<template>
    <v-card class="pa-5">
        <v-card-title class="font-weight-bold">最近のアクティビティ</v-card-title>
        <v-card-text class="activity_card">
            <v-list class="pb-0">
                <v-list-item
                v-for="(data,index) in deposit"
                :key="data.id"
                :class="`d-flex px-0 ${index > 0 ? 'mt-4':''}`"
                >
                <v-row class="d-flex align-center flex-grow-1 flex-wrap activity_item">
                    <v-col cols="6" class="me-auto pe-2 ">
                        <h4 class="font-weight-semibold activity_title">
                            {{ data.name }}
                        </h4>
                        <span class="text-sm activity_subtitle">{{ data.time }}</span>
                    </v-col>

                    <v-col cols="6" class="me-auto pe-2 " >
                        <h4 class="font-weight-semibold activity_title">
                            {{ data.status }}
                        </h4>
                        <span class="text-sm activity_subtitle">{{ data.subcontent }}</span>
                    </v-col>
                </v-row>
                </v-list-item>
            </v-list>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
        <v-btn text class="card-actions-btn">
            全ての取引を見る
        </v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
export default {
    name: "createTemplate",
    computed: {},
    components: { },
    data: () => ({
      deposit : [
        {
            name: '光田直子様',
            time: '12分前',
            status: 'メール登録',
            subcontent: '大和民族大學校シナリオ'
        },
        {
            name: '光田直子様',
            time: '12分前',
            status: 'メール登録',
            subcontent: '大和民族大學校シナリオ'
        },
        {
            name: '光田直子様',
            time: '12分前',
            status: 'メール登録',
            subcontent: '大和民族大學校シナリオ'
        },
        {
            name: '光田直子様',
            time: '12分前',
            status: 'メール登録',
            subcontent: '大和民族大學校シナリオ'
        },
        {
            name: '光田直子様',
            time: '12分前',
            status: 'メール登録',
            subcontent: '大和民族大學校シナリオ'
        },
        {
            name: '光田直子様',
            time: '12分前',
            status: 'メール登録',
            subcontent: '大和民族大學校シナリオ'
        },
        {
            name: '光田直子様',
            time: '12分前',
            status: 'メール登録',
            subcontent: '大和民族大學校シナリオ'
        },
        {
            name: '光田直子様',
            time: '12分前',
            status: 'メール登録',
            subcontent: '大和民族大學校シナリオ'
        },
        {
            name: '光田直子様',
            time: '12分前',
            status: 'メール登録',
            subcontent: '大和民族大學校シナリオ'
        },
        {
            name: '光田直子様',
            time: '12分前',
            status: 'メール登録',
            subcontent: '大和民族大學校シナリオ'
        },
        {
            name: '光田直子様',
            time: '12分前',
            status: 'メール登録',
            subcontent: '大和民族大學校シナリオ'
        },
        {
            name: '光田直子様',
            time: '12分前',
            status: 'メール登録',
            subcontent: '大和民族大學校シナリオ'
        },

        ]

    }),
    methods: {}
};
</script>