<template>
  <v-btn depressed color="#fff" width="145" height="35" min-width="145" class="ml-4 setting-btn">
    <v-icon color="#00A497">mdi-cog</v-icon>
    <span class="ml-1">セッティング</span>
  </v-btn>
</template>

<script>
export default {
  name: "SeoSettings",
  components: {},
  computed: {},
  data() {
    return {};
  },
  methods: {},
};
</script>


<style scoped lang="scss">
.setting-btn{
    span{
      color: #00A497;
    }
  }
</style>