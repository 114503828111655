<template>
  <div
    class="add-new-row-modal"
    id="rowModal"
    :style="{ top: modalAttrs.yPosition, left: modalAttrs.xPosition }"
    :class="ShowRowModal"
  >
    <div class="modal-item" @click="addRow(1)">
      <span class="item-header">
        <img
          class="before"
          :src="require('@/assets/images/svg/Rectangle222.svg')"
          alt=""
        />
        <img
          class="after"
          :src="require('@/assets/images/svg/Rectangle222white.svg')"
          alt=""
        />
      </span>
      <span class="item-footer">1カラム</span>
    </div>
    <div class="modal-item" @click="addRow(2)">
      <span class="item-header">
        <div class="item-svg">
          <div class="item-double">
            <img
              class="before"
              :src="require('@/assets/images/svg/Rectangle223.svg')"
              alt=""
            />
            <img
              class="after"
              :src="require('@/assets/images/svg/Rectangle223white.svg')"
              alt=""
            />
          </div>

          <div class="item-double">
            <img
              class="before"
              :src="require('@/assets/images/svg/Rectangle223.svg')"
              alt=""
            />
            <img
              class="after"
              :src="require('@/assets/images/svg/Rectangle223white.svg')"
              alt=""
            />
          </div>
        </div>
      </span>
      <span class="item-footer">2カラム</span>
    </div>
    <div class="modal-item" @click="addRow(3)">
      <span class="item-header">
        <div class="item-svg-three">
          <div class="item-triple">
            <img
              class="before"
              :src="require('@/assets/images/svg/Rectangle224.svg')"
              alt=""
            />
            <img
              class="after"
              :src="require('@/assets/images/svg/Rectangle224white.svg')"
              alt=""
            />
          </div>
          <div class="item-triple">
            <img
              class="before"
              :src="require('@/assets/images/svg/Rectangle224.svg')"
              alt=""
            />
            <img
              class="after"
              :src="require('@/assets/images/svg/Rectangle224white.svg')"
              alt=""
            />
          </div>
          <div class="item-triple">
            <img
              class="before"
              :src="require('@/assets/images/svg/Rectangle224.svg')"
              alt=""
            />
            <img
              class="after"
              :src="require('@/assets/images/svg/Rectangle224white.svg')"
              alt=""
            />
          </div>
        </div>
      </span>
      <span class="item-footer">3カラム</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "RowModal",
  components: {},
  data() {
    return {
      ShowRowModal: false,
      modalAttrs: {},
    };
  },
  created() {
    this.$root.$on("showRowsModal", (event) => {
      this.ShowRowModal = event.showModal ? "active" : "";
      this.modalAttrs = {
        // xPosition: event.xPosition + "px",
        yPosition: event.yPosition + "px",
      };
    });
  },
  methods: {
    addRow(col) {
      this.ShowRowModal = "";
      this.$root.$emit("addRow", col);
    },
  },
};
</script>
