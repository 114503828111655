<template>
  <div>
    <template v-if="children.length">
      <Window
        v-for="(child, index) in children"
        :key="`child-${index}`"
        :id="`element-${index}`"
        :component-name="child.component"
        @select:element="$emit('select:element', $event)"
        :is-section="false"
        :is-empty="false"
      >
        <component :is="child.component" :data="child.data" />
      </Window>
    </template>
    <template v-else>
      <Window component-name="EmptyElement" is-empty>
        <EmptyElement @select:element="$emit('select:element')" />
      </Window>
    </template>
  </div>
</template>

<script>
import Window from "@/components/Window";
import TextElement from "@/components/elements/TextElement";
import ButtonElement from "@/components/elements/ButtonElement";
import EmptyElement from "@/components/elements/EmptyElement";
export default {
  name: "WrapperElement",
  components: { EmptyElement, Window, TextElement, ButtonElement },
  props: {
    children: Array,
  },
};
</script>

<style scoped></style>
