<template>
  <div>
    <section
      class="sidebar-section"
      :class="toggleSidbar == true ? 'sidebar__show' : 'sidebar__none'"
    >
      <div class="sidebar">
        <div class="sidebar__typs">
          <div
            class="sidebar__typs__item types__one"
            @click="toggleSidbarContent('One')"
          >
            <span><i class="fal fa-window"></i></span>
            ブロック
          </div>
          <div
            class="sidebar__typs__item types__two"
            @click="toggleSidbarContent('Two')"
          >
            <span><i class="fal fa-border-all"></i></span>
            セクション
          </div>
          <div
            class="sidebar__typs__item types__three"
            @click="toggleSidbarContent('Three')"
          >
            <span><i class="fal fa-star"></i></span>
            エレメント
          </div>
        </div>
        <div
          class="sidebar__content-one"
          :class="showSidbarContent == 'One' ? 'show-content' : 'hide-content'"
        >
        <div class="sidebar-inner">
          <div class="sidebar__text">
            <span>サブヘッドスタイル</span>
          </div>
          <div class="sidebar__body">
            <div class="sidebar__body__slider">
              <div class="input__label">先頭位置</div>
              <div>
                <input
                  type="range"
                  min="1"
                  max="100"
                  value="35"
                  class="slider__color"
                  id="id1"
                />
              </div>
              <form>
                <input
                  class="sidebar__input"
                  type="number"
                  id="quantity"
                  name="quantity"
                  min="0"
                  max="100"
                  placeholder="px"
                />
              </form>
            </div>
            <div class="sidebar__body__slider">
              <div class="input__label">フォント</div>
              <form class="sidebar__select">
                <input
                  class="sitebar__input__field"
                  list="browsers"
                  name="browser"
                  value="ヒラギノ角ゴ"
                  style="width: 100%"
                />
                <datalist id="browsers"> </datalist>
              </form>
            </div>
            <div class="sidebar__body__slider">
              <div class="input__label">サイズ</div>
              <div>
                <input
                  type="range"
                  min="1"
                  max="100"
                  value="30"
                  class="slider__color"
                  id="id1"
                />
              </div>
              <form>
                <input
                  class="sidebar__input"
                  type="number"
                  id="quantity"
                  name="quantity"
                  min="0"
                  max="100"
                  placeholder="%"
                />
              </form>
            </div>
            <div class="sidebar__body__slider">
              <div class="sidebar__text__type">
                <button><i class="fas fa-bold"></i></button>
                <button><i class="fas fa-italic"></i></button>
                <button><i class="fas fa-underline"></i></button>
                <button><i class="fas fa-strikethrough"></i></button>
                <!-- <i class="far fa-link"></i> -->
              </div>
            </div>
            <div class="sidebar__body__type">
              <div class="input__label">テキストカラー</div>

              <div class="dropdown">
                <button
                  class="sidebar__body__btn"
                  id="colorText"
                  @click="toggleDropdownContent()"
                ></button>
                <div
                  class="dropdown-content"
                  :class="
                    dropDownContentVisible == true
                      ? 'dropdown-content-visible'
                      : ''
                  "
                >
                  <table class="color-group">
                    <tr>
                      <td><a href="#" class="color1-1"></a></td>
                      <td><a href="#" class="color1-2"></a></td>
                      <td><a href="#" class="color1-3"></a></td>
                      <td><a href="#" class="color1-4"></a></td>
                      <td><a href="#" class="color1-5"></a></td>
                      <td><a href="#" class="color1-6"></a></td>
                    </tr>

                    <tr>
                      <td><a href="#" class="color2-1"></a></td>
                      <td><a href="#" class="color2-2"></a></td>
                      <td><a href="#" class="color2-3"></a></td>
                      <td><a href="#" class="color2-4"></a></td>
                      <td><a href="#" class="color2-5"></a></td>
                      <td><a href="#" class="color2-6"></a></td>
                    </tr>

                    <tr>
                      <td><a href="#" class="color3-1"></a></td>
                      <td><a href="#" class="color3-2"></a></td>
                      <td><a href="#" class="color3-3"></a></td>
                      <td><a href="#" class="color3-4"></a></td>
                      <td><a href="#" class="color3-5"></a></td>
                      <td><a href="#" class="color3-6"></a></td>
                    </tr>

                    <tr>
                      <td><a href="#" class="color4-1"></a></td>
                      <td><a href="#" class="color4-2"></a></td>
                      <td><a href="#" class="color4-3"></a></td>
                      <td><a href="#" class="color4-4"></a></td>
                      <td><a href="#" class="color4-5"></a></td>
                      <td><a href="#" class="color4-6"></a></td>
                    </tr>

                    <tr>
                      <td><a href="#" class="color5-1"></a></td>
                      <td><a href="#" class="color5-2"></a></td>
                      <td><a href="#" class="color5-3"></a></td>
                      <td><a href="#" class="color5-4"></a></td>
                      <td><a href="#" class="color5-5"></a></td>
                      <td><a href="#" class="color5-6"></a></td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <!--new type  -->
            <div class="sidebar__body__type">
              <div class="new__body-type">
                <select class="rgb-dropdown-2">
                  <option value="bar">RGBつまみ</option>
                  <option value="circle" selected>色相環</option>
                  <option value="history">ヒストリー</option>
                </select>
              </div>
            </div>
            <!-- end new type -->
            <div class="sidebar__body__type">
              <div class="input__label">サブヘッド</div>
              <button class="sidebar__body__btn" id="bgColorText"></button>
              <button class="btn__color" @click="openColorPicker">
                <img
                  src="https://www.sessions.edu/wp-content/themes/divi-child/color-calculator/wheel-3-rgb.png"
                  alt=""
                />
              </button>
            </div>
            <!-- new type -->
            <div class="sidebar__body__type">
              <div class="new__body-type">
                <div class="body__type-one">
                  <div class="new__body-one">
                    <button
                      class="sidebar__body__btn"
                      id="bgColorTextOne"
                    ></button>
                    <button
                      class="btn__color btn__color-one"
                      @click="openColorPicker"
                    >
                      <img
                        src="https://www.sessions.edu/wp-content/themes/divi-child/color-calculator/wheel-3-rgb.png"
                        alt=""
                      />
                    </button>
                  </div>
                  <div class="new__body-one">
                    <button
                      class="sidebar__body__btn"
                      id="bgColorTextTwo"
                    ></button>
                    <button
                      class="btn__color btn__color-two"
                      @click="openColorPicker"
                    >
                      <img
                        src="https://www.sessions.edu/wp-content/themes/divi-child/color-calculator/wheel-3-rgb.png"
                        alt=""
                      />
                    </button>
                  </div>
                </div>
                <div class="body__type-two">
                  <p>動画:</p>
                  <div class="type__circle">
                    <div class="type__circle-child"></div>
                  </div>
                </div>
                <div class="body__type-three">
                  <div class="wrap__type-btn">
                    <span class="decrement" @click="changeAngle('minus')"
                      ><i class="fal fa-arrow-down"></i
                    ></span>
                    <span class="increment" @click="changeAngle('plus')"
                      ><i class="fal fa-arrow-right"></i
                    ></span>
                  </div>
                  <div class="type__btn-degree">
                    <span class="current_value">{{ angleValue }}</span
                    >&deg;
                  </div>
                </div>
              </div>
            </div>
            <!-- new type -->
            <div class="sidebar__body__slider">
              <div class="sidebar__text__types">
                <div class="sidebar__text__type">
                  <button><i class="fas fa-align-left"></i></button>
                  <button><i class="fas fa-align-center"></i></button>
                  <button><i class="fas fa-align-right"></i></button>
                </div>
                <div class="sidebar__text__type">
                  <button><i class="fas fa-list-ol"></i></button>
                  <button><i class="fas fa-list-ul"></i></button>
                  <button><i class="fas fa-tasks"></i></button>
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>

        <div
          class="sidebar__content-two"
          :class="showSidbarContent == 'Two' ? 'show-content' : 'hide-content'"
        ></div>

        <div
          class="sidebar__content-three"
          :class="
            showSidbarContent == 'Three' ? 'show-content' : 'hide-content'
          "
        >
          <div class="sidebar__text">
            <span>サブヘッドスタイル</span>
          </div>
          <div class="sidebar__body">
            <div class="sidebar__body__slider">
              <div class="input__label">先頭位置</div>
              <div>
                <input
                  type="range"
                  min="1"
                  max="100"
                  value="35"
                  class="slider__color"
                  id="id1"
                />
              </div>
              <form>
                <input
                  class="sidebar__input"
                  type="number"
                  id="quantity"
                  name="quantity"
                  min="0"
                  max="100"
                  placeholder="px"
                />
              </form>
            </div>
          </div>
          <div class="sidebar__body__slider">
            <div class="input__label">フォント</div>

            <form>
              <input
                class="sidebar__input_you"
                type="number"
                id="quantity"
                name="quantity"
                min="0"
                max="100"
                placeholder="YouTube"
              />
            </form>
          </div>
          <div class="sidebar__body__slider">
            <div class="input__label">サイズ</div>
            <form class="sidebar__select">
              <input
                class="sitebar__input__field"
                list="browsers"
                name="browser"
                value="src=//www.youtube.com"
                style="width: 100%"
              />
              <datalist id="browsers"> </datalist>
            </form>
          </div>
          <div class="sidebar__body__slider">
            <div class="input__label">テキス</div>
            <div>
              <input
                type="range"
                min="1"
                max="100"
                value="100"
                class="slider__color"
                id="id1"
              />
            </div>
            <form>
              <input
                class="sidebar__input"
                type="number"
                id="quantity"
                name="quantity"
                min="0"
                max="100"
                placeholder="100%"
              />
            </form>
          </div>
          <div class="sidebar__body__slider">
            <div class="input__label">サブヘ</div>
            <label class="switch">
              <input type="checkbox" />
              <span class="slider round"></span>
            </label>
          </div>
          <div class="sidebar__body__slider">
            <div class="input__label">サ</div>

            <form>
              <input
                class="sidebar__input_last"
                type="number"
                id="quantity"
                name="quantity"
                min="0"
                max="100"
                placeholder="サ"
              />
            </form>
          </div>
          <div class="sidebar__body__slider">
            <div class="input__label">サ</div>
            <div>
              <input
                type="range"
                min="1"
                max="100"
                value="35"
                class="slider__color"
                id="id1"
              />
            </div>
            <form>
              <input
                class="sidebar__input"
                type="number"
                id="quantity"
                name="quantity"
                min="0"
                max="100"
                placeholder="px"
              />
            </form>
          </div>
          <div class="sidebar__body__slider">
            <div class="input__label">Lorem</div>
            <button class="sidebar__body__btn"></button>
          </div>
        </div>
        <div class="sidebar__footer">
          <div class="sidebar__footer__item sidebar__footer__active">全て</div>
          <div class="sidebar__footer__item">
            <span><i class="fas fa-tv"></i></span>
            デスクトップのみ
          </div>
          <div class="sidebar__footer__item">
            <span><i class="fas fa-mobile-alt"></i></span>
            <span>モバイルのみ</span>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "TopBar",
  components: {},
  computed: {},
  data() {
    return {
      toggleSidbar: true,
      showSidbarContent: "One",
      dropDownContentVisible: false,
      angleValue: "0",
      showColorPicker: false,
      colorsObject: {},
      element: "",
    };
  },
  methods: {
    toggleSidbarContent(content) {
      this.showSidbarContent = content;
    },

    toggleDropdownContent() {
      this.dropDownContentVisible = !this.dropDownContentVisible;
    },

    changeAngle(type) {
      if (type == "plus") {
        this.type == ++this.angleValue;
      } else {
        this.type == --this.angleValue;
      }
    },

    openColorPicker(event) {
      this.element = event.target.parentElement.parentElement.querySelector(
        ".sidebar__body__btn"
      );
      this.showColorPicker = !this.showColorPicker;
      this.$root.$emit("showColorPicker", this.showColorPicker);
    },
  },
  created() {
    this.$root.$on("SidebarToggleEvent", (event) => {
      this.toggleSidbar = event;
    });

    this.$root.$on("color", (event) => {
      this.element.style.backgroundColor = event;
    });
  },
};
</script>
