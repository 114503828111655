<template>
    <v-card class="pa-5">
        <v-card-title class="font-weight-bold">ランディングサイト売上推移</v-card-title>
        <v-card-text>
            <v-row>
                <v-col cols="2" class="border_right pt-0 pb-0">
                    <span class="text-caption">今日</span>
                    <h6 class="font-weight-bold text-h6">
                        0円
                    </h6>
                </v-col>
                <v-col cols="2" class="border_right pt-0 pb-0">
                    <span class="text-caption">1週間</span>
                    <h6 class="font-weight-bold text-h6">
                        119,800円
                    </h6>
                </v-col>
                <v-col cols="2" class="border_right pt-0 pb-0">
                    <span class="text-caption">1ヶ月</span>
                    <h6 class="font-weight-bold text-h6">
                        1,251,800円
                    </h6>
                </v-col>
                <v-col cols="2" class="pt-0 pb-0">
                    <span class="text-caption">1年</span>
                    <h6 class="font-weight-bold text-h6">
                        22,071,800円
                    </h6>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="10">
                    <v-autocomplete
                    v-model="selected"
                    :items="items"
                    chips
                    item-text="name"
                    item-value="name"
                    outlined
                    multiple
                    @change="adjustOptions"
                    >
                    <template v-slot:prepend-item>
                        <v-list-item>
                            <v-list-item-content>
                                <v-text-field 
                                v-model="searchTerm" 
                                outlined 
                                height=12
                                dense
                                prepend-inner-icon="mdi-magnify"
                                placeholder="ランディングサイトを検索" 
                                @input="filteredData">
                                </v-text-field>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-content>
                                <v-input class="font-weight-bold">ランディングサイト一覧
                                </v-input>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                    <template v-slot:selection="data">
                        <v-chip
                        v-bind="data.attrs"
                        :color="data.item.color"
                        :text-color="data.item.textcolor"
                        close-icon="mdi-close"
                        small
                        :input-value="data.selected"
                        close
                        @click:close="remove(data.item)"
                        class="chipClass"
                        >
                        {{ data.item.name }}
                        </v-chip>
                    </template>
                    <template v-slot:append-item>
                        <v-divider></v-divider>
                        <v-list-item>
                            <v-list-item-content>
                                <v-input class="font-weight-bold">選択中のラインディングサイト
                                </v-input>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-content>
                                <v-checkbox
                                label="アカウント全体"
                                color="primary"
                                value="checked"
                                ></v-checkbox>
                            </v-list-item-content>
                        </v-list-item>
                        <div class="text-center">
                            <v-btn
                            outlined 
                            large 
                            color="grey-dark" 
                            class="btn-default mx-2"
                            @click="select"
                            >
                            適用
                            </v-btn>
                            <div class="text-sm-caption">一度に3つまで選択することができます
                            </div>
                        </div>
                    </template>
                    </v-autocomplete>
                </v-col>
                <v-col class="3">
                    <v-select
                    v-model="state"
                    :items="states"
                    outlined>
                    </v-select>
                </v-col>
            </v-row>
            <BarChart ref="barchart"/>
        </v-card-text>

    </v-card>
</template>
<script>
import BarChart from '@/components/configuration/Dashboard/BarChart';
export default {
    name: "Chart",
    computed: {},
    components: { BarChart },
    data () {
        return {
            backgroundcolor:['#82D1AF','#298B56','#184A45'],
            textcolor:['#000','#fff','#fff'],
            cdata: [],
            searchTerm: "",
            copied:[],
            selected: [{ name: 'タルムード001', color: '#82D1AF'}],
            items: [
                { name: 'タルムード001', color: '#82D1AF'},
                { name: '孝経001', color: '#13D4F5' },
                { name: '孝経002', color: '#9314F5'},
                { name: '孝経003', color: '#93D415' },
                { name: '孝経004', color: '#00D4F5'},
                { name: '孝経005', color: '#ffD4F5' },
                { name: '孝経006', color: '#9300F5'},
                { name: '孝経007', color: '#93D400'},
            ],

            states: [
                '1週間',
                '1ヶ月',
                '1年'
            ],
            state: '1年',
        }
    },
    mounted() {
        this.copied = [...this.items];
    },
    methods: {
        filteredData() {
            if (!this.searchTerm) {
                this.items = this.copied;
            }
            
            this.items = this.copied.filter((item) => {
                return item.name.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1;
            });
        },
        remove (item) {
            const index = this.selected.indexOf(item.name)
            if (index >= 0) this.selected.splice(index, 1)
            this.select()
            this.adjustOptions()
        },
        
        select(){
            this.cdata=[]
            this.selected.forEach(element => {
                this.items.forEach(item => {
                    if(item.name == element)
                    this.cdata.push(item) 
                })
            });
            this.$refs.barchart.color(this.cdata)
        },
        adjustOptions() {
            if(this.selected.length>0)
                this.selected.forEach((element, eindex) => {
                    this.items.forEach((item, index) => {
                        if(item.name == element ){
                            this.items[index].color = this.backgroundcolor[eindex]
                            this.items[index].textcolor = this.textcolor[eindex]
                        }
                    })
                })
            if (this.selected.length >= 3) {
                this.items.forEach((item, index) => {
                    this.items[index].disabled = true
                    
                })
                this.selected.forEach(element => {
                    this.items.forEach((item, index) => {
                        if(item.name == element ){
                            this.items[index].disabled = false
                        }
                    })
                })
            } else {
                this.items.forEach((item, index) => {
                    this.items[index].disabled = false
                })
            }
        }
    },
};
</script>
