<template>
<router-link to="/" class="dashboard-logo">
  <img src="@/assets/images/svg/logo.svg" alt="" height="30px">
  <span class="ml-2 mt-1">COMPANY</span>
  </router-link>
</template>

<script>

export default {
  name: "Logo",
  components: {  },
  computed: {},
  data() {
    return{
      
    }
  },
  methods: {
  },
};
</script>

<style scoped lang="scss">
  .dashboard-logo{
    display: flex;
    align-items: center;
    span{
      color: #fff;
    }
  }
</style>
