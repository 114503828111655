<template>
<div>
    <a class="nav-link dropdown-toggle flex dropdownbtn drdn"  v-on:click="dropdownClik">
    <img src="@/assets/images/svg/Group161.svg" alt="" height="33px" class="drdn pl-6">
    <div class="ml-2 uname drdn">
        <span class="uname-jp drdn">加藤一郎</span>
        <span class="uname-en drdn">Ichiro Kato  Owner</span>
    </div>
    </a>
    <div class="dropdown-menu dropdown-user show" v-if="showMenu" v-click-outside="onClickOutside">
    <a class="nav-link flex" v-on:click="onClickOutside">
        <img src="@/assets/images/svg/Group161.svg" alt="" height="33px" class="">
        <div class="ml-2 uname ">
        <span class="uname-jp ">加藤一郎</span>
        <span class="uname-en ">Ichiro Kato  Owner</span>
        </div>
    </a>
    <a href="#" class="dropdown-item">
        <i class="fa fa-info-circle" aria-hidden="true"></i>
        <span class="mx-1"> QAページ</span> 
    </a>
    <a href="#" class="dropdown-item">
        <i class="fas fa-yen-sign" aria-hidden="true"></i>
        <span class="mx-1">ストライプアカウント登録</span>
    </a>
    <a href="#" class="dropdown-item">
        <i class="fa fa-sign-out-alt" aria-hidden="true"></i>
        ログアウト
    </a>
    </div>
</div>

</template>

<script>

export default {
    name: "UserMenu",
    components: { },
    computed: {},
    data() {
        return {
            showMenu: false
        };
    },
    methods: {
        dropdownClik() {
            this.showMenu=true;
        },
        onClickOutside(){
            this.showMenu=false;
        }
    },
};
</script>
