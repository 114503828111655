<template>
  <v-app>
    <!-- <v-app-bar app color="primary" dark> </v-app-bar> -->
    <router-view />
    <!-- <v-main>
      
    </v-main> -->
  </v-app>
</template>

<script>

export default {
  name: "App",
  components: {},
  data() {
    return{

    }
  },
};
</script>

<style src="./styles/_base.scss" lang="scss" />
