<template>
  <div>
    <TopBar :view.sync="view" />
    <v-main>
      <div class="content-wrapper test">
        <div class="form-container" id="form-container">
          <Template :view="view" />
        </div>
        <RightSidBar />
        <SidbarToggle />
        <RgbSection />
      </div>
    </v-main>
    <!-- <ElementsModal /> -->
  </div>
</template>

<script>
import TopBar from "@/components/configuration/TopBar";
import RightSidBar from "@/components/configuration/RightSidBar";
import SidbarToggle from "@/components/configuration/SidbarToggle";
import RgbSection from "@/components/configuration/RgbSection";
import Template from "@/components/configuration/Template";
// import ElementsModal from "@/components/configuration/ElementsModal";

export default {
  name: "createTemplate",

  components: {
    TopBar,
    RightSidBar,
    SidbarToggle,
    RgbSection,
    // ElementsModal,
    Template,
  },

  data() {
    return {
      view: 'desktop',
    }
  },

};
</script>
<style src="./Editor.scss" lang="scss" />
