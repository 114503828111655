<template>
  <div
    :class="
      !isEmpty
        ? `window--wrapper-${isSection ? 'section' : 'element'}`
        : 'window--wrapper-empty'
    "
  >
    <div
      class="tools"
      @mouseenter.stop="!isEmpty ? (hovered = true) : ''"
      @mouseleave.stop="!isEmpty ? (hovered = false) : ''"
    >
      <div>
        {{ isSection && !isEmpty ? "Section" : "Element" }} {{ id }} (Type:
        {{ componentName }}df)
      </div>
      <slot />
      <div
        class="flex justify-center text-center btn-container"
        :class="{ 'btn-container--hovered': hovered }"
        style="width: 100%"
      >
        <v-btn
          @click="addElement()"
          fab
          :color="isSection && !isEmpty ? 'success' : 'primary'"
          class="btn-add-comp"
          x-small
          ><v-icon>mdi-plus</v-icon></v-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Window",
  props: {
    id: String,
    componentName: String,
    isSection: Boolean,
    isEmpty: Boolean,
  },
  data() {
    return {
      hovered: false,
    };
  },
  methods: {
    addElement() {
      let event = "select:element";
      if (this.isSection) {
        event = "add:section";
      }

      this.$emit(event, this.id);
    },
  },
};
</script>

<style lang="scss">
.btn {
  &-container {
    opacity: 0;

    &--hovered {
      opacity: 1;
    }
  }
}
.window {
  &--wrapper {
    &-section {
      .tools {
        border: 3px transparent solid;
        &:hover {
          border: 3px #4caf50 solid;
        }
      }
    }
    &-element {
      .tools {
        border: 3px transparent solid;
        &:hover {
          border: 3px #1976d2 solid;
        }
      }
    }
    &-empty {
      .tools {
        &:hover {
          border: 3px transparent solid;
        }
      }
    }
  }
}
</style>
