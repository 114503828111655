import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import createTemplate from "../views/Template/Create";
import Dashboard from "../views/Dashboard/Dashboard";
import CreateSite from "../views/CreateSite/CreateSite";

Vue.use(VueRouter);

const routes = [
  {
    path: "/editor",
    name: "Home",
    component: Home,
  },
  {
    path: "/landing-site/edit",
    name: "createTemplate",
    component: createTemplate,
  },
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/create-site",
    name: "CreateSite",
    component: CreateSite,
  },

];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
