<template>
<v-app-bar app fixed color="#00A497" height="55" class="px-4">
    <logo></logo>
    <v-spacer></v-spacer>
    <user-menu></user-menu>
    <!-- <div class="d-flex">
      <router-link to="/" class="text-decoration-none" v-if="$route.name != 'Dashboard'" >
        <v-btn class="mx-10">
            <i class="fas fa-caret-left "></i>
            <span>戻る</span>
        </v-btn>
      </router-link>
      <v-btn
      color="#fff"
      class="mr-5 new-site-blk"
      v-if="$route.name == 'Dashboard'"
      >
        <v-icon color="#00A497">mdi-web</v-icon>
        <span class="ml-1">新規サイト作成</span>
      </v-btn>
      
    </div> -->
</v-app-bar>
</template>

<script>
import Logo from "@/components/configuration/Dashboard/Logo";
import UserMenu from "@/components/configuration/Dashboard/UserMenu";

export default {
  name: "TopBar",
  components: { Logo, UserMenu },
  computed: {
    
  },
  data() {
    return {

    }
  },
  methods: {
  },
};
</script>


<style scoped lang="scss">
  
</style>