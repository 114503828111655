<template>
    <div id="graph-container">
        <canvas id="densityChart" ></canvas>
        
    </div>
</template>

<script>
import Chart from 'chart.js'

export default {
  name: 'BarChart',
  
  data() {
    return {
        ChartDataList: {
            
            labels: ["Mercury", "Venus", "Earth", "Mars", "Jupiter", "Saturn", "Uranus", "Neptune"],
            datasets: [
                {
                    name:'タルムード001',
                    label: false,
                    data: [5427, 5243, 5514, 3933, 1326, 687, 1271, 1638],
                    backgroundColor: '#82D1AF'
                },
                {
                    name:'孝経001',
                    label: false,
                    data: [1200, 500, 1300, 3000, 0, 1250, 3200, 1350],
                    backgroundColor: '#298B56',
                },
                {
                    name:'孝経002',
                    label: false,
                    data: [5427, 5243, 5514, 3933, 1326, 687, 1271, 1638],
                    backgroundColor: '#184A45',
                },
                {
                    name:'孝経003',
                    label: false,
                    data: [5427, 5243, 5514, 3933, 1326, 687, 1271, 1638],
                    backgroundColor: '#13D400',
                },
                {
                    name:'孝経004',
                    label: false,
                    data: [5427, 5243, 5514, 3933, 1326, 687, 1271, 1638],
                    backgroundColor: '#13D400',
                },
                {
                    name:'孝経005',
                    label: false,
                    data: [5427, 5243, 5514, 3933, 1326, 687, 1271, 1638],
                    backgroundColor: '#13D400',
                },
                {
                    name:'孝経006',
                    label: false,
                    data: [5427, 5243, 5514, 3933, 1326, 687, 1271, 1638],
                    backgroundColor: '#13D400',
                },
                {
                    name:'孝経007',
                    label: false,
                    data: [5427, 5243, 5514, 3933, 1326, 687, 1271, 1638],
                    backgroundColor: '#13D400',
                }
            ]
        }
        
    }
 },
computed: {
    chartdata(){
        return{
            labels: ["Mercury", "Venus", "Earth", "Mars", "Jupiter", "Saturn", "Uranus", "Neptune"],
            datasets: [
                {
                    name:'タルムード001',
                    label: false,
                    data: [5427, 5243, 5514, 3933, 1326, 687, 1271, 1638],
                    backgroundColor: '#82D1AF'
                }
            ]
        }
    }
},
methods: {
    renderBarChart: function(){
        
        
        Chart.elements.Rectangle.prototype.draw = function() {

            var ctx = this._chart.ctx;
            var vm = this._view;
            var left, right, top, bottom, signX, signY, borderSkipped, radius;
            var borderWidth = vm.borderWidth;

            // If radius is less than 0 or is large enough to cause drawing errors a max
            //      radius is imposed. If cornerRadius is not defined set it to 0.
            var cornerRadius = this._chart.config.options.cornerRadius;
            if(cornerRadius < 0){ cornerRadius = 0; }
            if(typeof cornerRadius == 'undefined'){ cornerRadius = 0; }

            if (!vm.horizontal) {
                // bar
                left = vm.x - vm.width / 2;
                right = vm.x + vm.width / 2;
                top = vm.y;
                bottom = vm.base;
                signX = 1;
                signY = bottom > top? 1: -1;
                borderSkipped = vm.borderSkipped || 'bottom';
            } else {
                // horizontal bar
                left = vm.base;
                right = vm.x;
                top = vm.y - vm.height / 2;
                bottom = vm.y + vm.height / 2;
                signX = right > left? 1: -1;
                signY = 1;
                borderSkipped = vm.borderSkipped || 'left';
                
            }
            // Canvas doesn't allow us to stroke inside the width so we can
            // adjust the sizes to fit if we're setting a stroke on the line
            
            if (borderWidth) {
                // borderWidth shold be less than bar width and bar height.
                var barSize = Math.min(Math.abs(left - right), Math.abs(top - bottom));
                borderWidth = borderWidth > barSize? barSize: borderWidth;
                var halfStroke = borderWidth / 2;
                // Adjust borderWidth when bar top position is near vm.base(zero).
                var borderLeft = left + (borderSkipped !== 'left'? halfStroke * signX: 0);
                var borderRight = right + (borderSkipped !== 'right'? -halfStroke * signX: 0);
                var borderTop = top + (borderSkipped !== 'top'? halfStroke * signY: 0);
                var borderBottom = bottom + (borderSkipped !== 'bottom'? -halfStroke * signY: 0);
                // not become a vertical line?
                if (borderLeft !== borderRight) {
                    top = borderTop;
                    bottom = borderBottom;
                }
                // not become a horizontal line?
                if (borderTop !== borderBottom) {
                    left = borderLeft;
                    right = borderRight;
                }
            }

            ctx.beginPath();
            ctx.fillStyle = vm.backgroundColor;
            ctx.strokeStyle = vm.borderColor;
            ctx.lineWidth = borderWidth;

            // Corner points, from bottom-left to bottom-right clockwise
            // | 1 2 |
            // | 0 3 |
            var corners = [
                [left, bottom],
                [left, top],
                [right, top],
                [right, bottom]
            ];

            // Find first (starting) corner with fallback to 'bottom'
            var borders = ['bottom', 'left', 'top', 'right'];
            var startCorner = borders.indexOf(borderSkipped, 0);
            
            if (startCorner === -1) {
                startCorner = 0;
            }

            function cornerAt(index) {
                return corners[(startCorner + index) % 4];
            }

            // Draw rectangle from 'startCorner'
            var corner = cornerAt(0);
            
            ctx.moveTo(corner[0], corner[1]);

            for (var i = 1; i < 4; i++) {
                corner = cornerAt(i);
                var nextCornerId = i+1;
                if(nextCornerId == 4){
                    nextCornerId = 0
                }
                var width = corners[2][0] - corners[1][0];
                var height = corners[0][1] - corners[1][1];
                
                var x = corners[1][0];
                var y = corners[1][1];

                radius = cornerRadius;
                
                // Fix radius being too large
                if(radius > Math.abs(height)/2){
                    radius = Math.floor(Math.abs(height)/2);
                }
                if(radius > Math.abs(width)/2){
                    radius = Math.floor(Math.abs(width)/2);
                }
                    //Positive Value
                    ctx.moveTo(x + radius, y);
                    ctx.lineTo(x + width - radius, y);
                    // top right
                    ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
                    ctx.lineTo(x + width, y + height - radius);
                    // bottom right
                    ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
                    ctx.lineTo(x + radius, y + height);
                    // bottom left
                    // ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
                    ctx.lineTo(x, y + height, x, y + height - radius);
                    ctx.lineTo(x, y + radius);
                    // top left
                    // ctx.quadraticCurveTo(x, y, x + radius, y);
                    ctx.lineTo(x, y, x + radius, y);
            }

            ctx.fill();
            if (borderWidth) {
                ctx.stroke();
            }
        };
            
        if(this.chartdata.datasets.length==1){
            Chart.pluginService.register({
            beforeRender: function(chart) {
                if (chart.config.options.showAllTooltips) {
                // create an array of tooltips
                // we can't use the chart tooltip because there is only one tooltip per chart
                chart.pluginTooltips = [];
                chart.config.data.datasets.forEach(function(dataset, i) {
                
                    chart.getDatasetMeta(i).data.forEach(function(sector) {
                    chart.pluginTooltips.push(new Chart.Tooltip({
                        _chart: chart.chart,
                        _chartInstance: chart,
                        _data: chart.data,
                        _options: chart.options.tooltips,
                        _active: [sector]
                    }, chart));
                    });
                });
                // turn off normal tooltips
                chart.options.tooltips.enabled = false;
                }
            },
            afterDraw: function(chart, easing) {
                if (chart.config.options.showAllTooltips) {
                    // we don't want the permanent tooltips to animate, so don't do anything till the animation runs atleast once
                    if (!chart.allTooltipsOnce) {
                        if (easing !== 1)
                        return;
                        chart.allTooltipsOnce = true;
                    }
                    // turn on tooltips
                    chart.options.tooltips.enabled = true;
                    Chart.helpers.each(chart.pluginTooltips, function(tooltip) {
                        tooltip.initialize();
                        tooltip.update();
                        // we don't actually need this since we are not animating tooltips
                        tooltip.pivot();
                        tooltip.transition(easing).draw();
                    });
                    chart.options.tooltips.enabled = false;
                    }
                }
            });
        }else{
            Chart.pluginService.register({
            beforeRender: function(chart) {
               
                chart.pluginTooltips = [];
                
                chart.options.tooltips.enabled = true;
            },
            afterDraw: function(chart, easing) {
                
                    // turn on tooltips
                    chart.options.tooltips.enabled = true;
                    Chart.helpers.each(chart.pluginTooltips, function(tooltip) {
                        tooltip.initialize();
                        tooltip.update();
                        tooltip.pivot();
                        tooltip.transition(easing).draw();
                     
                    });
            }    
            });
        }
        
        const ctx = document.getElementById('densityChart');

        new Chart(ctx, 
        {   
            type: "horizontalBar",
            data: this.chartdata,
            options: {
                legend: {
                    display: false
                },
                
                elements: {
                    rectangle: {
                        borderSkipped: 'left',
                    },
                },
                responsive: true,
                lineTension: 1,
                scales: {
                    yAxes: [
                        {
                            ticks: {
                                beginAtZero: true,
                                padding: 25
                            }
                        }
                    ]
                },
                tooltips: {
                    xAlign : 'left',
                    displayColors:	false,
                    backgroundColor: '#000',
                    callbacks: {
                        title: function() {
                            return ''
                        },
                        label: function(t) {
                            return t.xLabel + " 万円"
                        }
                    },
                },
                showAllTooltips: true,
                cornerRadius: 20,
            }}
        );
    },
    color(data){
        var newdatasets=[];
        this.ChartDataList.datasets.forEach(el=>{
            data.forEach(item=>{
                if(item.name==el.name){
                    el.backgroundColor=item.color
                    newdatasets.push(el)
                }
            })
            
        })
        this.chartdata.datasets=newdatasets;
        console.log(newdatasets)
        this.renderBarChart();
    },

},
mounted() {
    this.renderBarChart();
},
// watch: {
//     data: function() {
//     //   this._chart.destroy();
//       //this.renderChart(this.data, this.options);
//       this.renderBarChart();
//     }
// }
}
</script>

<style scoped>
#densityChart{
    width: 100% !important;
    height: 500px !important;
}
</style>

